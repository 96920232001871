<template>
    <div class="form-group col-12">
        <div class="traffics-note alert text-justify">
            <label class="col-form-label font-bold text-primary">Đoạn code gắn vào Footer web đích</label>
            <div class="form-control p-3 px-4 mb-2 d-block" style="background:#3b3f5c;color:#fff;height:auto">
                <pre class="language-js text-white mb-0">
&lt;div id='can-i-help-you' style="text-align: center"&gt;&lt;/div&gt;
&lt;input id="get_confirm" onclick="myFunctionCopy();"/&gt;
&lt;script src='https://cdn.traffic60s.com/traffic/ican.js?v=2022' type='text/javascript'&gt;&lt;/script&gt;</pre
                >
            </div>
            <p class="mb-2">+ Copy toàn bộ code này và gắn vào Footer của website</p>
            <p class="mb-2">+ Cần tắt các plugin <b>Wprocket</b> (nếu có) để tối ưu với thời gian thực</p>
            <p class="mb-2">+ Sau khi cài đặt thành công web sẽ hiện lên ô nhận mã màu trắng như sau:</p>
            <center><img src="/assets/images/traffics/traffic_1.jpg" alt="img" width="auto" height="auto" /></center>
            <p class="mb-2">
                + Sau khi vào <b>Google.com</b> tìm từ khóa rồi click vào web thấy xuất hiện nút <b>LẤY PASS</b> màu đỏ
                như hình dưới đây nghĩa là bạn đã cài đặt thành công.
            </p>
            <center><img src="/assets/images/traffics/traffic_2.jpg" alt="img" width="auto" height="auto" /></center>
        </div>
        <div class="traffics-note alert mb-1 text-justify">
            <p class="cl-red font-bold mb-0 text-uppercase">
                <label for="scripts" class="font-bold text-primary">Những lưu ý khi dùng traffic user:</label>
            </p>
            <p class="cl-red font-bold mb-0">
                1. Nên đẩy traffic user khi từ khoá đã vào được ít nhất từ trang 1-5 để thành viên có thể tìm thấy và
                click vào web.
            </p>
            <p class="cl-red font-bold mb-0">
                2. Nên đẩy traffic user ít nhất từ 20-30 ngày để có hiệu quả tốt nhất.
            </p>
            <p class="cl-red font-bold mb-0">
                3. Khi đẩy key chính nên đẩy kèm với key brand để tăng tính tự nhiên cho website (Đẩy key brand tốt cho
                key chính).
            </p>
            <p class="cl-red font-bold mb-0">
                4. Traffic user là chất xúc tác giúp từ khóa lên TOP nhanh hơn, tuy nhiên website cần phải tối ưu onpage
                và backlink thật tốt trước khi đẩy traffic.
            </p>
            <p class="cl-red font-bold mb-0">
                5. Quý khách nên chủ động theo dõi dữ liệu từ <b>Google Analytics</b> và <b>Google Search Console</b> để
                đối chiếu với dữ liệu thống kê của chúng tôi.
            </p>
            <p class="cl-red font-bold mb-0">
                6. Chúng tôi cam kết 100% traffic user người dùng là thật, không tool, không fake IP, quý khách có thể
                tự mình làm nhiệm vụ để kiểm chứng (Xem video hướng dẫn cách tự check
                <a class="font-bold text-underline" href="https://www.youtube.com/" target="_blank">
                    https://www.youtube.com/</a
                >)
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "traffics-note"
}
</script>

<style>
.traffics-note {
    width: 100%;
    background-color: #fef1f3;
    border-radius: 4px;
    position: relative;
}

.traffics-note .text-underline {
    text-decoration: underline;
}
</style>
